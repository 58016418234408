<template>
  <div v-if="meetReport.data.length > 0">
    <b-table-simple
      hover
      caption-top
      caption-bottom
      responsive
      bordered
    >
      <b-thead>
        <b-tr>
          <b-th
            class="text-nowrap align-middle b-table-sticky-column"
          >
            TEMAS TİPİ
          </b-th>
          <b-th
            class="text-nowrap align-middle"
          >
            TOPLAM
          </b-th>
          <b-th
            v-for="(row,key) in meetReport.data"
            :key="key"
            class="text-nowrap align-middle text-center"
          >
            {{ row.day }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-thead>
        <b-tr
          v-for="(meet,mkey) in meetReport.data[0].meets"
          :key="mkey"
        >
          <b-th class="text-nowrap align-middle b-table-sticky-column">
            {{ meet.meet }}
          </b-th>
          <b-td class="text-center">
            {{ meetReport.meetsTotal[mkey].count | toNumber }}
          </b-td>
          <b-td
            v-for="(count,cKey) in meetReport.data"
            :key="cKey"
            :class="count.meets[mkey].count > 0? 'bg-primary text-white font-weight-bold text-center' : 'text-center'"
          >
            {{ count.meets[mkey].count | toNumber }}
          </b-td>
        </b-tr>
      </b-thead>
      <b-tfoot>
        <b-tr>
          <b-th class="text-nowrap align-middle b-table-sticky-column">
            Genel Toplam
          </b-th>
          <th
            v-for="(total,tKey) in meetReport.grandTotal"
            :key="tKey"
            class="text-nowrap align-middle text-center"
          >
            {{ total | toNumber }}
          </th>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>
<script>
import {
  BTableSimple, BTd, BTfoot, BTh, BThead, BTr,
} from 'bootstrap-vue'

export default {
  name: 'Meets',
  components: {
    BTableSimple,
    BTd,
    BTfoot,
    BTh,
    BThead,
    BTr,
  },
  data() {
    return {
      loading: null,
    }
  },
  computed: {
    meetReport() {
      return this.$store.getters['salesInterviews/getMeetReport']
    },
  },
}
</script>
<style scoped></style>
